<template>
  <div>
    <b-dropdown v-if="isConnected"
      :text="userAddress"
      class="m-md-2">
        <b-dropdown-item
          :href="'/users/' + currentUser.wallet.address">
            My profile &amp; lands
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          v-on:click="disconnect">
            Log out
        </b-dropdown-item>
    </b-dropdown>
    <b-button v-else
        squared
        variant="danger"
        class="my-2 my-sm-0"
        v-on:click="connect"
        >Connect</b-button>
  </div>
</template>

<script>
import { connectToWallet, isWalletConnected, installNetworkChangeDetection, getWallet } from "/src/utils/wallet.js";
import { checkSession } from "/src/services/auth.js"
import { to, truncate_address } from "/src/utils"

async function checkToken(wallet, store) {
  const [error, response] = await to(checkSession());
  if (error) {
    console.log("Network error?");
    store.dispatch('auth/logout');
  } else {
    if ((response.data.wallet != wallet.address)||(response.data.network != wallet.network)) {
      console.log("Wrong session!");
      store.dispatch('auth/logout');
    } else {
      installNetworkChangeDetection();
    }
  }
}

export default {
  name: "Connect",
  computed: {
    isConnected() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    userAddress() {
      return truncate_address(this.$store.state.auth.user.wallet.address);
    },
  },
  created: async function() {
    if (this.$store.state.auth.status.loggedIn) {
      var connected = await isWalletConnected();
      if (connected) {
        console.log("Wallet is connected!");
        // Check token...
        var wallet = getWallet();
        checkToken(wallet, this.$store);
      } else {
        const [error, wallet] = await to(connectToWallet());
        console.log(wallet);
        if (error) {
          console.log("Wallet is not connected!");
          this.$store.dispatch('auth/logout');
        } else {
          console.log("Wallet is connected!");
          // Check token...
          checkToken(wallet, this.$store);
        }
      }
    }
  },
  methods: {
    async connect() {
      const [error, wallet] = await to(connectToWallet());
      if (error) {
        console.log(error);
        await this.$bvModal.msgBoxOk('Error connecting to wallet', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        });
      } else {
        try {
          await this.$store.dispatch('auth/login', wallet);
          installNetworkChangeDetection();
        } catch (error) {
          console.log(error);
          await this.$bvModal.msgBoxOk('Error connecting to the wallet. Check you\'re in the right network: ' + process.env.VUE_APP_BLOCKCHAIN, {
            title: 'Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true
          });
        }
      }
    },
    disconnect() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to logout?', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        hideHeaderClose: false,
        centered: true
      })
        .then(confirm => {
          if (confirm) {
            this.$store.dispatch('auth/logout');
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  }
}
</script>
