<template>
  <b-modal
    id="modal-mint"
    ref="modal"
    title="Mint land"
    @show="resetModal"
    size="lg"
    ok-only
    ok-title="Close"
  >

  <b-card
    v-bind:title="land.name"
    v-bind:img-src="land.thumbnail_url"
    img-top
    class="mb-3 text-center"
    align="center"
    >
      <b-card-text align="left" class="mt-2">
        <b-row>
          <b-col md="6" offset-md="2">
              <p>Land <strong>#{{ land.id }}</strong><br/>
              Size: <strong>{{ (land.max_x - land.min_x + 1) * (land.max_y - land.min_y + 1) }}</strong><br/>
              Coordinates: <strong>({{ land.min_x }}, {{ land.min_y }}) - ({{ land.max_x }}, {{ land.max_y }})</strong><br/>
              </p>
          </b-col>
        </b-row>
      </b-card-text>

      <b-button
        v-if="!transaction"
        v-on:click="mint"
        size="lg"
        :disabled="inProgress"
        variant="warning">
        <b-spinner
          v-if="inProgress"
          label="Spinning">
          </b-spinner>
        Mint for {{ mintPrice }} eth
      </b-button>

      <b-card-text
        v-if="transaction"
      >
        <h5>Land was minted successfully! 🔥</h5>
        <br/>
        <p>Transaction: <strong><b-link :href="transaction_base_url + transaction.hash">{{ transaction.hash }}</b-link></strong></p>
        <p>Your new land: <b-link :to="'/lands/' + land.id">{{ land.name || 'Land #' + land.id }}</b-link></p>
        <p><small class="text-secondary">Please, wait a couple of minutes while everything is setup, and then refresh your browser if needed.</small></p>
      </b-card-text>
  </b-card>

  </b-modal>
</template>

<script>
import { to } from "/src/utils"
import { getMintPrice, mint } from "/src/utils/wallet.js";

export default {
  name: "ImageModal",
  components: {
  },
  props: ["land"],
  data: () => {
    return {
      mintPrice: "1",
      transaction: null,
      inProgress: false,
      transaction_base_url: process.env.VUE_APP_TRANSACTIONS_BASE_URL,
    }
  },
  methods: {
    async resetModal() {
      this.mintPrice = await getMintPrice(this.land.id);
      this.transaction = null;
      this.inProgress = false;
    },
    closeModal() {
      this.$bvModal.hide('modal-mint');
    },
    async mint() {
      this.inProgress = true;
      const [mint_error, tx] = await to(mint(this.land.id, this.mintPrice));

      if (mint_error) {
        alert("Sorry, there was an error.");
      } else {
        this.transaction = tx;

        const [wait_error,] = await to(tx.wait());

        if (wait_error) {
          alert("Sorry, there was an error but still the transaction could be successful.");
        }
      }

      this.inProgress = false;
    },
  },
}
</script>

<style scoped>
  .card-img-top {
    border: 1px solid #888;
  }
</style>
