export function to(promise) {
  return promise.then(data => [null, data])
    .catch(err => [err]);
}

export function truncate_address(address){
  var node = document.createElement('div');
  node.innerHTML = address;
  var content = node.textContent;
  return content.slice(0, 5) + "…" + content.slice(-5);
}
