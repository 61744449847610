<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-navbar-brand href="/"><img src="../assets/mukabe-icon.png"/></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto mr-5">
        <b-nav-item-dropdown text="Mukabe" right>
          <b-dropdown-item @click="navigateToCoordinates(0, 0, 0)">Home</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(1, 0, 1)">About us</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(1, 1, 2)">Roadmap</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(0, 1, 3)">How it works</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(-1, 1, 4)">Whitepaper</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(-1, 0, 5)">Why</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-text style="font-weight: bold;">
            <strong>Tutorial</strong>
          </b-dropdown-text>
          <b-dropdown-item @click="navigateToCoordinates(-1, -1, 6)">First step</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(0, -1, 7)">Connect &amp; mint</b-dropdown-item>
          <b-dropdown-item @click="navigateToCoordinates(1, -1, 8)">Edit &amp; manage</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Contact" right>
          <b-dropdown-item href="https://twitter.com/MukabeLand" target="_blank"><font-awesome-icon :icon="['fab', 'twitter']" /> Twitter</b-dropdown-item>
          <b-dropdown-item href="https://discord.gg/5ap68Ktd" target="_blank"><font-awesome-icon :icon="['fab', 'discord']" /> Discord</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="mailto:team@mukabe.land"><font-awesome-icon icon="envelope" /> Support</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :href="collectionURL" target="_blank">Collection</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-form>
          <Connect/>
        </b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {eventBus} from "/src/main.js";
import Connect from './Connect.vue'

export default {
  name: 'Navigation',
  components: {
    Connect,
  },
  computed: {
    collectionURL() {
      return process.env.VUE_APP_COLLECTION_URL;
    },
  },
  methods: {
    navigateToCoordinates(x, y, position) {
      if ((this.$route.name === "map") || (this.$route.name === "patch")) {
        eventBus.$emit('navigateToCoordinates', {x: x, y: y});
      } else {
        this.$router.push('/' + position);
      }
    },
  },
  props: {
  }
}
</script>

<style scoped>
  .bg-light {
    background-color: #fff !important;
  }

  .navbar {
		z-index: 999;
    -webkit-box-shadow: 0 8px 6px -6px #999;
    -moz-box-shadow: 0 8px 6px -6px #999;
    box-shadow: 0 8px 6px -6px #999;

    background:transparent;
    background:rgba(255,255,255,0.8);

    padding: 0;
    margin: 0;
  }
  .navbar-brand {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
    float:none;
    display:inline-block;
    vertical-align:middle;
    margin-right:-4px;
  }
  .has-feedback .form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
  }

  .navbar-collapse {
    padding-left: 30px;
    padding-right: 30px;
  }

  .navbar-form-ns {
    padding-left: 5px;
    padding-right: 0px;
  }

  .navbar-form-ns-right {
    padding-right: 0px;
  }

  .navbar-form-s-right {
    padding-left: 5px;
    padding-right: 25px;
  }
</style>
