// import Web3 from 'web3';
import { ethers, utils } from "ethers";
import { ABI } from "./abi.js";

var provider = null;
var signer;

export async function connectToWallet() {
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  var network = await provider.getNetwork();

  var wallet = await provider.send("eth_requestAccounts", []);

  signer = provider.getSigner()

  return {
    "address": wallet[0],
    "network": network.chainId,
  };
}

export function installNetworkChangeDetection() {
  if (provider != null) {
    provider.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        console.log("Blockchain network change detected!");
        window.location.reload();
      }
    });
  }
}

export async function isWalletConnected() {
  if (provider == null)
    return false;
  else {
    const accounts = await provider.listAccounts();
    return accounts.length > 0;
  }
}

export async function getWallet() {
  var wallet = await provider.send("eth_requestAccounts", []);
  var network = await provider.getNetwork();

  return {
    "address": wallet[0],
    "network": network.chainId,
  };
}

export async function sign(message) {
  var signature = await signer.signMessage(message);
  return signature;
}

export async function getMintPrice(tokenId) {
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_ADDRESS, ABI, signer);

  const price = await contract.getMintPrice(tokenId);

  return utils.formatEther(price);
}

export async function mint(tokenId, price) {
  const contract = new ethers.Contract(process.env.VUE_APP_CONTRACT_ADDRESS, ABI, signer);

  return await contract.mint(tokenId, {value: utils.parseEther(price)});
}

export async function getError(txHash) {
  const tx = await provider.getTransaction(txHash);

  try {
    await provider.call(tx, tx.blockNumber);
    return "";
  } catch (err) {
    const code = err.data.replace('Reverted ','');
    // console.log({err});
    let reason = ethers.utils.toUtf8String('0x' + code.substr(138));
    return reason;
  }
}
