export const ABI = 
[
    {
        "anonymous": false, 
        "inputs": [
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "owner", 
                "type": "address"
            }, 
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "approved", 
                "type": "address"
            }, 
            {
                "indexed": true, 
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "Approval", 
        "type": "event"
    }, 
    {
        "anonymous": false, 
        "inputs": [
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "owner", 
                "type": "address"
            }, 
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "operator", 
                "type": "address"
            }, 
            {
                "indexed": false, 
                "internalType": "bool", 
                "name": "approved", 
                "type": "bool"
            }
        ], 
        "name": "ApprovalForAll", 
        "type": "event"
    }, 
    {
        "anonymous": false, 
        "inputs": [
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable24x24Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable16x16Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable8x8Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable5x5Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable3x3Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable2x2Id", 
                "type": "uint256"
            }, 
            {
                "indexed": false, 
                "internalType": "uint256", 
                "name": "_maxAvailable1x1Id", 
                "type": "uint256"
            }
        ], 
        "name": "Available", 
        "type": "event"
    }, 
    {
        "anonymous": false, 
        "inputs": [
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "from", 
                "type": "address"
            }, 
            {
                "indexed": true, 
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "indexed": true, 
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "Transfer", 
        "type": "event"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "approve", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "owner", 
                "type": "address"
            }
        ], 
        "name": "balanceOf", 
        "outputs": [
            {
                "internalType": "uint256", 
                "name": "", 
                "type": "uint256"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [], 
        "name": "contractURI", 
        "outputs": [
            {
                "internalType": "string", 
                "name": "", 
                "type": "string"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "getApproved", 
        "outputs": [
            {
                "internalType": "address", 
                "name": "", 
                "type": "address"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "getMintPrice", 
        "outputs": [
            {
                "internalType": "uint256", 
                "name": "", 
                "type": "uint256"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "owner", 
                "type": "address"
            }, 
            {
                "internalType": "address", 
                "name": "operator", 
                "type": "address"
            }
        ], 
        "name": "isApprovedForAll", 
        "outputs": [
            {
                "internalType": "bool", 
                "name": "", 
                "type": "bool"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "maxAvailable24x24Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable16x16Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable8x8Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable5x5Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable3x3Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable2x2Id_", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "maxAvailable1x1Id_", 
                "type": "uint256"
            }
        ], 
        "name": "makeAvailable", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "mint", 
        "outputs": [], 
        "stateMutability": "payable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "mintTo", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [], 
        "name": "name", 
        "outputs": [
            {
                "internalType": "string", 
                "name": "", 
                "type": "string"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "ownerOf", 
        "outputs": [
            {
                "internalType": "address", 
                "name": "", 
                "type": "address"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "from", 
                "type": "address"
            }, 
            {
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "safeTransferFrom", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "from", 
                "type": "address"
            }, 
            {
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }, 
            {
                "internalType": "bytes", 
                "name": "data", 
                "type": "bytes"
            }
        ], 
        "name": "safeTransferFrom", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "operator", 
                "type": "address"
            }, 
            {
                "internalType": "bool", 
                "name": "approved", 
                "type": "bool"
            }
        ], 
        "name": "setApprovalForAll", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenType", 
                "type": "uint256"
            }, 
            {
                "internalType": "uint256", 
                "name": "price", 
                "type": "uint256"
            }
        ], 
        "name": "setMintPrice", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }, 
    {
        "inputs": [], 
        "name": "symbol", 
        "outputs": [
            {
                "internalType": "string", 
                "name": "", 
                "type": "string"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "tokenURI", 
        "outputs": [
            {
                "internalType": "string", 
                "name": "", 
                "type": "string"
            }
        ], 
        "stateMutability": "view", 
        "type": "function"
    }, 
    {
        "inputs": [
            {
                "internalType": "address", 
                "name": "from", 
                "type": "address"
            }, 
            {
                "internalType": "address", 
                "name": "to", 
                "type": "address"
            }, 
            {
                "internalType": "uint256", 
                "name": "tokenId", 
                "type": "uint256"
            }
        ], 
        "name": "transferFrom", 
        "outputs": [], 
        "stateMutability": "nonpayable", 
        "type": "function"
    }
]
;
