<template>
  <div id="page" class="overflow-auto">
    <div id="content">
      <h3><font-awesome-icon icon="flag" /> Land</h3>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col md="10" offset-md="1">
            <b-card v-bind:title="land.name || '[No land name]'"
                    v-bind:img-src="land.thumbnail_url"
                    img-left
                    img-height="500px"
                    tag="article"
                    class="mb-3"
              >
              <p v-if="hasOwner">
                <font-awesome-icon icon="user" /> Owned by <b-link :to="'/users/' + land.owner.address">{{ owner_username || '[No user name]' }}</b-link>
              </p>
              <p v-else><small class="text-secondary"><em>Land available for minting</em></small></p>
              <p>Land <strong>#{{ land.id }}</strong><br/>
              Size: <strong>{{ (land.max_x - land.min_x + 1) * (land.max_y - land.min_y + 1) }}</strong><br/>
              Coordinates: <strong>({{ land.min_x }}, {{ land.min_y }}) - ({{ land.max_x }}, {{ land.max_y }})</strong><br/>
              </p>
              <p>
              <b-button
                squared
                variant="primary"
                v-b-modal.modal-mint
                v-if="land.enabled && !land.minted && userLoggedIn"
                >Mint land</b-button>
              <b-button
                squared
                disabled
                v-if="land.enabled && !land.minted && !userLoggedIn"
                >Connect wallet to mint</b-button>
              <b-button
                v-b-modal.modal-edit-land-info
                v-if="userLoggedIn && (currentUserWalletAddress === land.owner.address)"
                variant="primary">Edit</b-button>
              <p>
              <p>
                <b-button :href="land.map_url" variant="outline-primary"><font-awesome-icon icon="map" /> See land in map</b-button>
              </p>
              <p v-if="land.minted"><b-button variant="warning" :href="land.trading_url" target="_blank"><font-awesome-icon icon="shopping-cart" /> Trade in OpenSea</b-button></p>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <hr/>
      <h4>Patches</h4>
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="pageSize"
        @change="handlePageChange"
        ></b-pagination>
      <p class="mt-3">Number of patches: {{ count }}</p>
      <div id="patches" class="overflow-auto">
        <b-table
          sticky-header
          id="table-patches"
          :items="patchesTable"
          small
          >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.key === 'edit' ? '500px' : 'auto' }"
            >
          </template>
          <template #cell(position)="data">
            <b-link :to="'/' + data.value">{{ data.value }}</b-link>
          </template>
          <template #cell(thumbnail)="data">
            <b-link :to="'/' + data.value.position">
              <b-img rounded :src="data.value.image_src" alt="" style="width: 64px"/>
            </b-link>
          </template>
          <template #cell(edit)="data">
            <b-button
              v-if="userLoggedIn && (currentUserWalletAddress === land.owner.address)"
              variant="outline-primary"
              @click="editPatchModal(data.value)">
              <font-awesome-icon icon="edit" /> Edit info
            </b-button>
            &nbsp;
            <b-button
              v-if="userLoggedIn && (currentUserWalletAddress === land.owner.address)"
              variant="outline-primary"
              @click="uploadImageModal(data.value)">
              <font-awesome-icon icon="image" /> Upload image
            </b-button>
            &nbsp;
            <b-button
              v-if="userLoggedIn && (currentUserWalletAddress === land.owner.address)"
              variant="outline-primary"
              @click="selectNFTModal(data.value)">
              <font-awesome-icon icon="images" /> Select NFT
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

    <EditLandModal
      :land="land"
      />

    <MintModal
    :land="land"
    />

    <ImageModal
      :patch="editPatch"
      />

    <EditPatchInfo
      :patch="editPatch"
      />

    <SelectNFTModal
      :patch="editPatch"
      />
  </div>
</template>

<script>
import { to, truncate_address } from "/src/utils"
import { getLand, getLandPatches } from "/src/services/lands.js"
import EditLandModal from "./EditLandModal.vue"
import MintModal from "./MintModal.vue"
import ImageModal from "./ImageModal.vue"
import EditPatchInfo from "./EditPatchInfo.vue"
import SelectNFTModal from "./SelectNFTModal.vue"

export default {
  name: "Land",
  computed: {
    hasOwner: function() {
      return this.land.owner.address;
    },
    currentUserWalletAddress() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user.wallet.address;
      } else {
        return false;
      }
    },
    owner_username() {
      if ((this.$store.state.auth.status.loggedIn) && (this.$store.state.auth.user.wallet.address == this.land.owner.address)) {
        return "You";
      } else {
        return this.land.owner.name || truncate_address(this.land.owner.address);
      }
    },
    userLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  components: {
    EditLandModal,
    MintModal,
    ImageModal,
    EditPatchInfo,
    SelectNFTModal,
  },
  data() {
    return {
      land: {
        id: '',
        name: '',
        thumbnail_url: '',
        trading_url: '',
        map_url: '',
        enabled: true,
        minted: false,
        owner: {
          address: '',
          name: '',
          uuid: '',
        },
        min_x: null,
        min_y: null,
        max_x: null,
        max_y: null,
      },
      page: 1,
      count: 0,
      pageSize: 100,
      patchesTable: [],
      editPatch: {
        position: null,
        title: '',
        description: '',
        description_html: '',
        zoom_color: '',
      },
    };
  },
  mounted () {
  },
  methods: {
    uploadImageModal: function(patch) {
      this.editPatch = patch;
      this.$nextTick(() => {
        this.$bvModal.show("modal-image");
      });
    },
    selectNFTModal: function(patch) {
      this.editPatch = patch;
      this.$nextTick(() => {
        this.$bvModal.show("modal-select-nft");
      });
    },
    editPatchModal: function(patch) {
      this.editPatch = patch;
      this.$nextTick(() => {
        this.$bvModal.show("modal-edit-patch-info");
      });
    },
    reloadPatches: async function() {
      const [patches_error, patches_response] = await to(getLandPatches(this.$route.params.id, this.page));
      if (patches_error) {
        alert("Sorry, there was an error.");
      } else {
        this.count = patches_response.data.count;
        this.patchesTable = [];
        if (this.$store.state.auth.status.loggedIn && (this.$store.state.auth.user.wallet.address === this.land.owner.address)) {
          patches_response.data.results.forEach(element => {
            this.patchesTable.push({
              position: element.position,
              title: element.title,
              thumbnail: {
                image_src: element.thumbnail_xs_url,
                position: element.position,
              },
              edit: {
                position: element.position,
                title: element.title,
                description: element.description,
                description_html: element.description_html,
                zoom_color: element.zoom_color,
              },
            });
          });
        } else {
          patches_response.data.results.forEach(element => {
            this.patchesTable.push({
              position: element.position,
              title: element.title,
              thumbnail: {
                image_src: element.thumbnail_xs_url,
                position: element.position,
              },
            });
          });
        }
      }
    },
    handlePageChange: async function(value) {
      this.page = value;

      await this.reloadPatches();
    },
  },
  beforeMount: async function() {
    const [error, response] = await to(getLand(this.$route.params.id));
    if (error) {
      alert("Sorry, there was an error.");
    } else {
      this.land.id = response.data.id;
      this.land.name = response.data.name;
      this.land.thumbnail_url = response.data.thumbnail_url;
      this.land.trading_url = response.data.trading_url;
      this.land.map_url = response.data.map_url;
      this.land.enabled = true;
      if ("owner" in response.data) {
        this.land.minted = true;
        this.land.owner.address = response.data.owner.address;
        this.land.owner.name = response.data.owner.name;
        this.land.owner.uuid = response.data.owner.uuid;
      } else {
        this.land.minted = false;
        this.land.owner.address = '';
        this.land.owner.name = '';
        this.land.owner.uuid = '';
      }
      this.land.min_x = response.data.min_x;
      this.land.min_y = response.data.min_y;
      this.land.max_x = response.data.max_x;
      this.land.max_y = response.data.max_y;

      document.title = this.land.name || '[No land name]';
    }

    await this.reloadPatches();
  },
};
</script>

<style scoped>
  #page {
    height:100vh;
		overflow-y: scroll;
  }
  #content {
    margin-top: 3em;
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 3em;
  }
  #patches {
    height:50vh;
		overflow-y: scroll;
  }
  .card-img-left {
    border: 1px solid #888;
  }
</style>
