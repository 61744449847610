<template>
  <b-modal
    id="modal-select-nft"
    ref="modal"
    title="Select NFT"
    size="xl"
    scrollable
    @show="resetModal"
    ok-only
    ok-title="Back"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-card-group deck>
        <b-card
          v-for="nft in nfts"
          v-bind:key="nft.blockchain + ':' + nft.contract_address + ':' + nft.token_id"
          :title="nft.title"
          :img-src="nft.image_url"
          :img-alt="nft.title"
          img-top
          style="min-width: 20rem;max-width: 25rem;"
          class="mb-2"
        >
          <b-card-text>
            {{ nft.description }}
          </b-card-text>

          <b-button v-on:click="setNFT(nft)" variant="primary">Select</b-button>

          <template #footer>
            <small class="text-muted">{{ nft.blockchain }}</small>
          </template>
        </b-card>
      </b-card-group>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <p class="float-left text-warning">If the NFT image is not updated after uploading, please, try again in 10 minutes.</p>
        <b-button
          variant="primary"
          size="gm"
          class="float-right"
          @click="closeModal"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { to } from "/src/utils"
import { getConnectedUserNFTs, attachNFTToPatch } from "/src/services/nfts.js";

export default {
  name: "SelectNFTModal",
  components: {
  },
  props: ["patch"],
  data: () => {
    return {
      loading: false,
      nfts: [],
    }
  },
  methods: {
    async resetModal() {
      this.loading = true;
      const response = await getConnectedUserNFTs();
      this.nfts = response.data;
      this.loading = false;
    },
    async setNFT(nft) {
      this.loading = true;
      // eslint-disable-next-line no-unused-vars
      const [error, _] = await to(attachNFTToPatch(this.patch.position, nft));
      if (error) {
        alert("Sorry, there was an error. Please, try again later.");
      } else {
        this.$bvModal.hide('modal-select-nft');
        this.$bvToast.toast("The NFT was set. The map will be updated shortly, please refresh the page in a few minutes.", {
          title: 'Uploaded!',
          toaster: "b-toaster-bottom-right",
          variant: "success",
          autoHideDelay: 5000,
          appendToast: false,
          solid: true,
        });
      }
      this.loading = false;
    },
    closeModal() {
      this.$bvModal.hide('modal-select-nft');
    },
  },
}
</script>
