<template>
  <b-modal
    id="modal-edit-user-info"
    ref="modal"
    title="Edit user info"
    @show="resetModal"
    @ok="handleOk"
    >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        id="input-group-title"
        label="Name"
        label-for="input-title"
        description="Your name."
        >
        <b-form-input
          id="input-title"
          v-model="newUser.name"
          placeholder="Enter name"
          ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { updateUser } from "/src/services/users.js"

export default {
  name: "EditUserModal",
  props: ["user"],
  data() {
    return {
      newUser: {
        name: "",
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },
    resetModal() {
      this.newUser.name = this.user.name;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      updateUser(
        this.user.address,
        {
          "name": this.newUser.name,
        }
      )
        .then(response => {
          this.user.name = response.data.name;
        })
      // eslint-disable-next-line no-unused-vars
        .catch(_ => {
          alert("Sorry, there was an error!");
        })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-user-info')
      })
    }
  },
}
</script>
