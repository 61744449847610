import service from './index';
import authHeader from './auth_header';

export function get_login_message(wallet) {
  return service().post('/web3/login/message/', {
    network: wallet.network,
    address: wallet.address,
  });
}

export function login(wallet, signature) {
  return service().post('/web3/login/', {
    network: wallet.network,
    address: wallet.address,
    signature: signature,
  });
}

export function checkSession() {
  return service().get('/session/', { headers: authHeader() });
}
