<template>
  <div id="page" class="overflow-auto">
    <div id="content">
      <h3><font-awesome-icon icon="user" /> User</h3>
      <b-container fluid>
        <b-row class="mb-3">
          <b-col md="10" offset-md="1">
            <b-card v-bind:title="user.name || userAddress">
              <b-button
                v-b-modal.modal-edit-user-info
                v-if="currentUserWalletAddress === user.address"
                variant="primary">Edit</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <hr/>
      <h4>Lands</h4>
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="pageSize"
        @change="handlePageChange"
        ></b-pagination>
      <p class="mt-3">Number of lands: {{ count }}</p>
      <div id="lands" class="overflow-auto">
        <b-table
          id="table-patches"
          :items="lands"
          small
          >
          <template #cell(name)="data">
            <b-link :to="'/lands/' + data.value.id">{{ data.value.name }}</b-link>
          </template>
          <template #cell(thumbnail)="data">
            <b-link :to="'/lands/' + data.value.id">
              <b-img rounded :src="data.value.image_src" alt="" style="width: 64px"/>
            </b-link>
          </template>
        </b-table>
      </div>
    </div>
    <EditUserModal
      :user="user"
      />
  </div>
</template>

<script>
import { to, truncate_address } from "/src/utils"
import { getUser } from "/src/services/users.js"
import { getUserLands } from "/src/services/lands.js"
import EditUserModal from "./EditUserModal.vue"

export default {
  name: "User",
  computed: {
    currentUserWalletAddress() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user.wallet.address;
      } else {
        return null;
      }
    },
    userAddress() {
      return truncate_address(this.user.address);
    },
  },
  components: {
    EditUserModal,
  },
  data() {
    return {
      user: {
        name: '',
        address: '',
      },
      page: 1,
      count: 0,
      pageSize: 100,
      lands: [],
    };
  },
  mounted () {
  },
  methods: {
    reloadLands: async function() {
      const [lands_error, lands_response] = await to(getUserLands(this.$route.params.address, this.page));
      if (lands_error) {
        alert("Sorry, there was an error.");
      } else {
        this.count = lands_response.data.count;
        this.patches = [];
        lands_response.data.results.forEach(element => {
          this.lands.push({
            name: {
              name: element.name,
              id: element.id,
            },
            thumbnail: {
              image_src: element.thumbnail_xs_url,
              id: element.id,
            },
          });
        });
      }
    },
    handlePageChange: async function(value) {
      this.page = value;

      await this.reloadLands();
    },
  },
  beforeMount: async function() {
    const [error, response] = await to(getUser(this.$route.params.address));
    if (error) {
      alert("Sorry, there was an error.");
    } else {
      this.user.address = response.data.address;
      this.user.name = response.data.name;

      document.title = this.user.name || truncate_address(this.user.address);
    }

    await this.reloadLands();
  },
};
</script>

<style scoped>
  #page {
    height:100vh;
		overflow-y: scroll;
  }
  #content {
    margin-top: 3em;
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 3em;
  }
  #lands {
    height:50vh;
		overflow-y: scroll;
  }
</style>
