export function coordinates_to_position(x, y) {
  var d = Math.abs(y)

  if (x > d) {
    return (4*x - 3)*x + y;
  } else if (-x > d) {
    return -(-4*x + 1)*x - y;
  } else if (y > 0) {
    return (4*d - 1)*d - x;
  } else {
    return (4*d + 3)*d + x;
  }
}


export function position_to_coordinates(position) {
  var d = Math.floor((1. + Math.sqrt(position))/2.);
  var n_sig = position + 1 - (4*(d**2) + 1);

  if (n_sig <= -2*d) {
    return {x: d, y: 3*d + n_sig};
  } else if (n_sig <= 0) {
    return {x: -d - n_sig, y: d};
  } else if (n_sig <= 2*d) {
    return {x: -d, y: d - n_sig};
  } else {
    return {x: -3*d + n_sig, y: -d};
  }
}
