<template>
  <div style="height: 100%; width: 100%">
    <b-button-group vertical id="map-controls">
      <b-button variant="light" @click="navigateToCoordinates(0, 0)">
        <font-awesome-icon icon="home" />
      </b-button>
      <b-button
        variant="light"
        v-b-modal.modal-share
        >
        <font-awesome-icon icon="share-alt" />
      </b-button>
      <b-button
        variant="light"
        :pressed.sync="gridLayerVisible"
        v-on:click="showGridLayer"
        >
        <font-awesome-icon icon="th" />
      </b-button>
      <span class="btn-separator"></span>
      <b-button
        variant="light"
        v-if="currentZoom >= 18"
        @click="toggleInfoPanel"
        >
        <font-awesome-icon icon="info" />
      </b-button>
      <b-button
        variant="light"
        v-b-modal.modal-edit-patch-info
        v-if="currentUserWalletAddress === patch.land.owner.address"
        >
        <font-awesome-icon icon="edit" />
      </b-button>
      <b-button
        variant="light"
        v-b-modal.modal-image
        v-if="currentUserWalletAddress === patch.land.owner.address"
        >
        <font-awesome-icon icon="image" />
      </b-button>
      <b-button
        variant="light"
        v-b-modal.modal-select-nft
        v-if="currentUserWalletAddress === patch.land.owner.address"
        >
        <font-awesome-icon icon="images" />
      </b-button>
    </b-button-group>
    <div style="height: 100%; width: 100%">
      <l-map
        ref="map"
        :zoom="zoom"
        :maxBounds="maxBounds"
        :options="mapOptions"
        style="height: 100%"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @ready="ready"
        >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
          :options="layerOptions"
          />
        <l-control-attribution position="bottomright" :prefix="blockchain"></l-control-attribution>
      </l-map>
    </div>
    <EditPatchInfo
      :patch="patch"
      />
    <ImageModal
      :patch="patch"
      />
    <SelectNFTModal
      :patch="patch"
      />
    <ShareLink
      :share="share"
      />
    <InfoPanel
      v-if="currentZoom >= 18"
      :patch="patch"
      :loading="patch_loading"
      />
  </div>
</template>

<script>
import L from 'leaflet';
import { LatLngBounds } from "leaflet";
import { LMap, LTileLayer, LControlAttribution } from 'vue2-leaflet';

import {eventBus} from "/src/main.js";
import { MapConfig } from "/src/config.js"
import InfoPanel from "./InfoPanel.vue"
import EditPatchInfo from "./EditPatchInfo.vue"
import ImageModal from "./ImageModal.vue"
import SelectNFTModal from "./SelectNFTModal.vue"
import ShareLink from "./ShareLink.vue"
import { coordinates_to_position, position_to_coordinates } from "/src/utils/mukabe.js"
import { getPatch } from "/src/services/patches.js"

export default {
  name: "Map",
  created() {
    eventBus.$on('navigateToCoordinates', (coords) => {
      this.navigateToCoordinates(coords.x, coords.y);
    })
  },
  computed: {
    currentUserWalletAddress() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user.wallet.address;
      } else {
        return false;
      }
    },
  },
  components: {
    LMap,
    LTileLayer,
    LControlAttribution,
    InfoPanel,
    EditPatchInfo,
    ImageModal,
    SelectNFTModal,
    ShareLink,
  },
  data() {
    return {
      map: null,
      currentZoom: 11,
      //currentCenter: latLng(47.41322, -1.219482),
      currentCoords: {
        x: 0,
        y: 0,
      },
      gridLayerVisible: false,
      tilesGridOpts: {
        minZoom: MapConfig.MIN_GRID_ZOOM,
        maxZoom: MapConfig.MAX_ZOOM,
        maxNativeZoom: MapConfig.MAX_NATIVE_ZOOM,
      },

      share: {
        link: process.env.VUE_APP_BASE_URL,
        title: 'Mukabe',
        description: '',
        quote: '',
        hashtags: '',
        twitterUser: '',
      },

      blockchain: '<a href="' + process.env.VUE_APP_BLOCKCHAIN_URL + '" target="_blank">' + process.env.VUE_APP_BLOCKCHAIN + '</a>',

      zoom: 13,
      //center: latLng(47.41322, -1.219482),
      maxBounds: LatLngBounds(),

      url: `${process.env.VUE_APP_MAP_BASE_URL}/{z}/{x}/{y}.png?{t}`,
      attribution:
        '<a href="' + process.env.VUE_APP_BASE_URL + '">' + process.env.VUE_APP_CODENAME + '</a>',
      layerOptions: {
        t: function() { return Math.trunc((new Date()).getTime()/10000); },
        minZoom: MapConfig.MIN_ZOOM,
        maxZoom: MapConfig.MAX_ZOOM,
        maxNativeZoom: MapConfig.MAX_NATIVE_ZOOM,
        continuousWorld: false,
        tms: false,
        noWrap: true,
      },

      mapOptions: {
        minZoom: MapConfig.MIN_ZOOM,
        maxZoom: MapConfig.MAX_ZOOM,
        maxNativeZoom: MapConfig.MAX_NATIVE_ZOOM,
        zoomControl: false,
        zoomDelta: 1,
        zoomSnap: 0.25,
        wheelPxPerZoomLevel: 75,
        attributionControl: false, // XXX
      },
      patch_loading: false,
      patch: {
        position: null,
        title: '',
        description: '',
        description_html: '',
        x: '',
        y: '',
        zoom_color: '',
        available: false,
        land: {
          name: '',
          id: '',
          minted: false,
          enabled: false,
          owner: {
            address: '',
            name: '',
            uuid: '',
          },
          min_x: null,
          min_y: null,
          max_x: null,
          max_y: null,
        },
        nft: {
          enabled: false,
          verified: false,
        },
      },
    };
  },
  mounted () {
    this.gridLayerVisible = localStorage.getItem('gridLayerVisible') === "true";
  },
  watch:{
    $route (to, from){
      if (to.hash.startsWith("#") && (to.hash !== from.hash) && (to.hash !== this.currentHash)) {
        const coords = to.hash.substring(2).split("/");

        this.navigateToCoordinates(parseInt(coords[1]), parseInt(coords[2]), parseInt(coords[0]))
      }
    }
  },
  methods: {
    ready() {
      this.map = this.$refs.map.mapObject;

      // XXX FIXME
      this.maxBounds = new LatLngBounds(
        this.map.unproject([0, MapConfig.HEIGHT], MapConfig.MAX_NATIVE_ZOOM),
        this.map.unproject([MapConfig.WIDTH, 0], MapConfig.MAX_NATIVE_ZOOM)
      );

      // Add grid layer
      L.GridLayer.TilesGrid = L.GridLayer.extend({
        createTile: this.createTile,
      });

      L.gridLayer.tilesGrid = function(opts) {
        return new L.GridLayer.TilesGrid(opts);
      };

      if (this.gridLayerVisible) {
        this.gridLayer = L.gridLayer.tilesGrid(this.tilesGridOpts);
        this.map.addLayer(this.gridLayer);
      }

      if ((this.$route.params.position === undefined) && (this.$route.hash.startsWith("#"))) {
        const coords = this.$route.hash.substring(2).split("/");

        this.navigateToCoordinates(parseInt(coords[1]), parseInt(coords[2]), parseInt(coords[0]))
      } else {
        var position = (this.$route.params.position === undefined) ? 0 : parseInt(this.$route.params.position);

        this.navigateToPosition(position);
      }
    },
    navigateToPosition(position) {
      var coords = position_to_coordinates(position);
      this.navigateToCoordinates(coords.x, coords.y);
    },
    navigateToCoordinates(x, y, zoom = 18) {
      this.map.setView(this.map.unproject(
        [
          (x*256 + 128) + MapConfig.WIDTH/2,
          (y*256 + 128) + MapConfig.HEIGHT/2
        ],
        17),
        zoom,
      );
    },
    showGridLayer() {
      localStorage.gridLayerVisible = this.gridLayerVisible;

      if (this.gridLayerVisible) {
        this.gridLayer = L.gridLayer.tilesGrid(this.tilesGridOpts);
        this.map.addLayer(this.gridLayer);
      } else {
        this.map.removeLayer(this.gridLayer);
      }
    },
    toggleInfoPanel() {
      eventBus.$emit('toggleInfoPanel');
    },
    updateRoute() {
      if (this.currentZoom >= 18) {
        if (this.patch.position == 0) {
          if (this.$route.name !== "/") {
            this.$router.push("/").catch(()=>{});
            this.share.link = process.env.VUE_APP_BASE_URL;
          }
        } else {
          if (this.patch.position == null) {
            return;
          }
          let newRoute = this.patch.position.toString();
          if (this.$route.name !== newRoute) {
            this.$router.push(newRoute).catch(()=>{});
            this.share.link = process.env.VUE_APP_BASE_URL + "/" + newRoute;
          }
        }
      } else {
        let newHash = "#/" + this.currentZoom + "/" + this.currentCoords.x + "/" + this.currentCoords.y;
        if (this.$route.hash !== newHash) {
          this.$router.push({
            name: "map",
            hash: newHash,
          }).catch(()=>{});
          this.currentHash = newHash;
          this.share.link = process.env.VUE_APP_BASE_URL + "/" + newHash;
        }
      }
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      if (this.currentZoom < 18) {
        this.patch.position = null;
        this.patch.x = null;
        this.patch.y = null;
        this.patch.title = '';
        this.patch.description = '';
        this.patch.description_html = '';
        this.patch.zoom_color = '';
        this.patch.available = false;
        this.patch.land.name = '';
        this.patch.land.id = '';
        this.patch.land.thumbnail_url = '';
        this.patch.land.enabled = false;
        this.patch.land.minted = false;
        this.patch.land.owner.address = '';
        this.patch.land.owner.name = '';
        this.patch.land.owner.uuid = '';
        this.patch.land.min_x = null;
        this.patch.land.min_y = null;
        this.patch.land.max_x = null;
        this.patch.land.max_y = null;
        this.patch.nft = {
          enabled: false,
          verified: false,
        };

        this.share.title = "Mukabe";
        this.share.description = "The social map.";

        document.title = "Mukabe";
      }
      this.updateRoute();
    },
    centerUpdate(center) {
      this.currentCenter = center;
      if (this.currentZoom >= 18) {
        var p = this.map.project(this.currentCenter, 17);
        var p0 = [Math.round((p.x-128-33554432/2)/256), Math.round((p.y-128-33554432/2)/256)];

        var position = coordinates_to_position(p0[0], p0[1]);
        if (position != this.patch.position) {
          this.patch_loading = true;
          getPatch(position)
            .then(response => {
              this.patch.position = response.data.position;
              this.patch.x = response.data.x;
              this.patch.y = response.data.y;
              this.patch.title = response.data.title;
              this.patch.description = response.data.description;
              this.patch.description_html = response.data.description_html;
              this.patch.zoom_color = response.data.zoom_color;
              this.patch.available = true;
              this.patch.land.name = response.data.land.name;
              this.patch.land.id = response.data.land.id;
              this.patch.land.thumbnail_url = response.data.land.thumbnail_url;
              this.patch.land.enabled = true; // FIXME TODO
              if ("owner" in response.data.land) {
                this.patch.land.minted = true;
                this.patch.land.owner.address = response.data.land.owner.address;
                this.patch.land.owner.name = response.data.land.owner.name;
                this.patch.land.owner.uuid = response.data.land.owner.uuid;
              } else {
                this.patch.land.minted = false;
                this.patch.land.owner.address = '';
                this.patch.land.owner.name = '';
                this.patch.land.owner.uuid = '';
              }
              this.patch.land.min_x = response.data.land.min_x;
              this.patch.land.min_y = response.data.land.min_y;
              this.patch.land.max_x = response.data.land.max_x;
              this.patch.land.max_y = response.data.land.max_y;
              this.patch.nft = response.data.nft;

              this.share.title = response.data.title;
              this.share.description = response.data.description;

              if (position == 0) {
                /* this.$router.push("/").catch(()=>{}); */
                document.title = `${this.patch.title} — Mukabe`;
              } else {
                /* this.$router.push(`${this.patch.position}`).catch(()=>{}); */
                document.title = `/${this.patch.position}/ ${this.patch.title} - ${this.patch.land.name} – Mukabe`;
              }

              this.patch_loading = false;
              this.updateRoute();
            })
            // eslint-disable-next-line no-unused-vars
            .catch(_ => {
              this.patch.position = position;
              this.patch.x = p[0];
              this.patch.y = p[1];
              this.patch.title = '';
              this.patch.description = '';
              this.patch.description_html = '';
              this.patch.zoom_color = '';
              this.patch.available = false;
              this.patch.land.name = '';
              this.patch.land.id = '';
              this.patch.land.thumbnail_url = '';
              this.patch.land.enabled = false;
              this.patch.land.minted = false;
              this.patch.land.owner.address = '';
              this.patch.land.owner.name = '';
              this.patch.land.owner.uuid = '';
              this.patch.land.min_x = null;
              this.patch.land.min_y = null;
              this.patch.land.max_x = null;
              this.patch.land.max_y = null;
              this.patch.nft = {
                enabled: false,
                verified: false,
              };

              this.share.title = "Mukabe";
              this.share.description = "The social map.";

              if (position == 0) {
                if (this.patch.title) {
                  document.title = `${this.patch.title} — Mukabe`;
                }
                else {
                  document.title = `Mukabe`;
                }
              } else {
                document.title = `/${this.patch.position}/ ${this.patch.title} — Mukabe`;
              }

              this.patch_loading = false;
              this.updateRoute();
            });
        }
      } else {
        var zp = this.map.project(this.currentCenter, 17);

        this.currentCoords.x = Math.round((zp.x-128-33554432/2)/256);
        this.currentCoords.y = Math.round((zp.y-128-33554432/2)/256);

        this.updateRoute();
      }
    },
    createTile(coords) {
      var p;
      var c = 1;
      if (coords.z == 14) {
        p = 1;
        c = 8;
      } else if (coords.z == 15) {
        p = 1;
        c = 4;
      } else if (coords.z == 16) {
        p = 1;
        c = 2;
      } else if (coords.z == 17) {
        p = 1;
      } else if (coords.z == 18) {
        p = 2;
      } else if (coords.z == 19) {
        p = 4;
      } else if (coords.z == 20) {
        p = 8;
      }

      var canvas = document.createElement('canvas');

      var tileSize = this.gridLayer.getTileSize();
      canvas.setAttribute('width', tileSize.x * p);
      canvas.setAttribute('height', tileSize.y * p);

      var ctx = canvas.getContext('2d');

      // ctx.canvas.width = p*canvas.width;
      // ctx.canvas.height = p*canvas.height;

      ctx.globalAlpha = 0.2;
      ctx.lineWidth = 1;
      ctx.strokeStyle = '#000';

      ctx.beginPath();
      ctx.translate(0.5, 0.5);
      for (var i=0; i<c; i++) {
        for (var j=0; j<c; j++) {
          ctx.moveTo(j*canvas.height/c, canvas.height/c + i*canvas.height/c);
          ctx.lineTo(j*canvas.width/c, i*canvas.height/c);
          ctx.lineTo(canvas.width/c + j*canvas.width/c, i*canvas.height/c);
        }
      }
      ctx.stroke();

      return canvas;
    },
  }
};
</script>

<style scoped>
  .leaflet-container {
    background: #fff;
  }

  #map-controls {
      position: absolute;
      top: 120px;
      left: 10px;
      z-index: 999;
  }

  .btn-separator:after {
      content: ' ';
      display: block;
      float: left;
      //background: #ADADAD;
      margin: 0 10px;
      height: 15px;
      width: 1px;
  }
</style>
