import { get_login_message, login } from "/src/services/auth.js"
import { sign } from "/src/utils/wallet.js"
// import { to } from "/src/utils"

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, wallet) {
      var message_response = await get_login_message(wallet);

      var message = message_response.data.message;

      var signature = await sign(message);

      var response = await login(wallet, signature);

      var token = response.data.token;

      var user = {
        wallet: wallet,
        token: token,
        profile: {
          name: response.data.profile.name,
          uuid: response.data.profile.uuid,
        },
      }

      localStorage.setItem('user', JSON.stringify(user));

      commit('loginSuccess', user);
      Promise.resolve(user);
      // commit('loginFailure');
      // return Promise.reject(error);
    },
    logout({ commit }) {
      localStorage.removeItem('user');
      commit('logout');
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
};
