import axios from 'axios';
import service from './index';
import authHeader from './auth_header';

export function getPatch(position) {
  return service().get(`/patches/${position}/`, { headers: authHeader() });
}

export function updatePatch(position, data) {
  return service().patch(`/patches/${position}/`, data, { headers: authHeader() });
}

export function createUploadImageSignedURL(position) {
  return service().post(
    `/patches/${position}/image_upload_url/`,
    { image_format: "png" },
    { headers: authHeader() }
  );
}

export function uploadImage(image, filename, url) {
  // data.append(filename, image);
  // data[filename] = image;
  return axios.put(
    url,
    image,
    {
      headers: {
        'Content-Type': 'image/png',
      }
    }
  );
}
