<template>
  <b-modal
    id="modal-edit-patch-info"
    ref="modal"
    title="Edit patch info"
    @show="resetModal"
    @ok="handleOk"
  >
    <b-overlay :show="loading" rounded="sm">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          id="input-group-title"
          label="Title"
          label-for="input-title"
          description="The title of your patch."
        >
          <b-form-input
            id="input-title"
            v-model="newPatch.title"
            placeholder="Enter title"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-description"
          label="Description"
          label-for="input-description"
          description="Description of your patch."
        >
          <b-form-textarea
            id="input-description"
            v-model="newPatch.description"
            placeholder="Write some information about your patch."
            rows="3"
            max-rows="6"
            ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-zoom-color"
          label="Zoom color"
          label-for="zoom-color"
          description="The color your patch will fade to when you zoom out."
        >
          <color-picker v-model="newPatch.zoom_color" />
        </b-form-group>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
  import { updatePatch } from "/src/services/patches.js"

  export default {
    name: "EditPatchInfo",
    props: ["patch"],
    data() {
      return {
        loading: false,
        newPatch: {
          title: "",
          description: "",
          zoom_color: "#ffffff",
        },
      }
    },
    methods: {
      setPatch(patch) {
        this.patch = patch;
      },
      resetModal() {
        this.newPatch.title = this.patch.title;
        this.newPatch.description = this.patch.description;
        this.newPatch.zoom_color = this.patch.zoom_color;
      },
      handleOk(bvModalEvent) {
        // Prevent modal from closing
        bvModalEvent.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        this.loading = true;
        updatePatch(
          this.patch.position,
          {
            "title": this.newPatch.title,
            "description": this.newPatch.description,
            "zoom_color": this.newPatch.zoom_color.hex8,
          }
        )
        .then(response => {
          this.patch.title = response.data.title;
          this.patch.description = response.data.description;
          this.patch.description_html = response.data.description_html;
          let colorChanged = (this.patch.zoom_color !== response.data.zoom_color);
          this.patch.zoom_color = response.data.zoom_color;
          this.loading = false;
          if (colorChanged) {
            this.$bvToast.toast("The map will be updated shortly, please refresh the page in a few minutes.", {
              title: 'Done!',
              toaster: "b-toaster-bottom-right",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: false,
              solid: true,
            });
          } else {
            this.$bvToast.toast("Patch information was updated, please refresh the page if you don't see the changes immediately.", {
              title: 'Done!',
              toaster: "b-toaster-bottom-right",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: false,
              solid: true,
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => {
          alert("Sorry, there was an error! Please, try again later.");
          this.loading = false;
        })
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit-patch-info')
        })
      },
    },
  }
</script>
