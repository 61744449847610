<template>
  <div id="patch-info-panel">
    <b-overlay :show="loading" rounded="sm">
      <b-card no-body>
        <b-card-header
          @click="toggleInfoPanel"
          header-tag="nav"
          >
          <b-button
            @click="toggleInfoPanel"
            v-on:click.stop
            text-decoration-none
            variant="link"
            size="sm">
            <font-awesome-icon class="when-open" icon="chevron-down" v-if="infoPanelVisible " />
            <font-awesome-icon class="when-closed" icon="chevron-up" v-else />
          </b-button>
          <span
            style="margin-left: 1.0em"
            class="font-weight-bold">
            <small class="text-secondary">/{{ patch.position }}/</small>
            <span v-if="patch.available">
              {{ patch.title }}
              <span v-if="patch.nft.enabled && patch.nft.verified" id="nft-badge">
                <font-awesome-icon icon="certificate" :style="{ color: '#007bff' }"/>
              </span>
              <small><font-awesome-icon icon="flag" /> <b-link :to="'/lands/' + patch.land.id">{{ patch.land.name || '[Land #' + patch.land.id + ']' }}</b-link></small>
            </span>
            <span v-else>
              <small>&nbsp; <font-awesome-icon icon="flag" /> <em>Land not available yet</em></small>
            </span>
          </span>
        </b-card-header>
        <b-collapse id="collapse-info" class="mt-2" v-model="infoPanelVisible ">
          <b-card-body>
            <b-card-title class="text-center">
              <small class="text-secondary">/{{ patch.position }}/</small>
              <span v-if="patch.available && hasOwner">
                {{ patch.title }}
              </span>
            </b-card-title>

            <b-card-text class="mt-2">
              <b-container fluid>
                <b-row>
                  <b-col md="6" offset-md="2">
                    <p v-if="patch.available">
                      <span v-if="hasOwner && patch.nft.enabled && patch.nft.verified" id="nft-badge">
                        <b-badge pill variant="primary">
                          NFT verified
                          <font-awesome-icon icon="certificate" />
                        </b-badge>
                      </span>

                      <font-awesome-icon icon="flag" /> Belongs to <b-link :to="'/lands/' + patch.land.id">{{ patch.land.name || '[No land name]' }}</b-link>
                      &nbsp;
                      <span v-if="hasOwner">
                        |&nbsp;
                        <font-awesome-icon icon="user" /> Owned by <b-link :to="'/users/' + patch.land.owner.address">{{ ownerName || '[No user name]' }}</b-link>
                      </span>
                      <span v-else><small class="text-secondary"><em>Land available for minting</em></small></span>
                    </p>
                    <p v-else>
                      <font-awesome-icon icon="flag" /> <em>Land not available yet</em>
                    </p>
                  </b-col>
                  <b-col>
                    <b-button
                      squared
                      variant="primary"
                      v-b-modal.modal-mint
                      v-if="patch.land.enabled && !patch.land.minted && userLoggedIn"
                      >Mint land</b-button>
                    <b-button
                      squared
                      disabled
                      v-if="patch.land.enabled && !patch.land.minted && !userLoggedIn"
                      >Connect wallet to mint</b-button>
                  </b-col>
                </b-row>
                <b-row>
                   <b-col md="8" offset-md="2"><hr/><br/></b-col> 
                </b-row>
                <span v-if="patch.available && hasOwner">
                  <b-row>
                    <b-col v-html="patch.description_html" md="8" offset-md="2"></b-col>
                  </b-row>
                  <span v-if="patch.nft.enabled && patch.nft.verified">
                    <b-row>
                       <b-col md="8" offset-md="2"><br/><hr/></b-col> 
                    </b-row>
                    <b-row>
                      <b-col md="8" offset-md="2">
                        <br/>
                        <b-card no-body class="overflow-hidden" style="max-width: 540px;">
                          <b-row no-gutters>
                            <b-col md="6">
                              <b-card-img :src="patch.nft.image_url" alt="Image" class="rounded-0"></b-card-img>
                            </b-col>
                            <b-col md="6">
                              <b-card-body :title="patch.nft.title">
                                <b-card-text v-html="patch.nft.description_html">
                                </b-card-text>
                                <span v-if="patch.nft.external_url">
                                  <b-button :href="patch.nft.external_url" target="_blank" variant="outline-primary">Go collection web</b-button>
                                  <b-card-text>
                                    <small class="text-warning">Go to this link at your own risk.</small>
                                  </b-card-text>
                                </span>
                              </b-card-body>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col> 
                    </b-row>
                  </span>
                </span>
              </b-container>

            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <MintModal
      :land="patch.land"
      />
    </b-overlay>
  </div>
</template>

<script>
import {eventBus} from "/src/main.js";
import { truncate_address } from "/src/utils"
import MintModal from "./MintModal.vue"

export default {
  name: 'InfoPanel',
  mounted () {
    this.infoPanelVisible = localStorage.getItem('infoPanelVisible') === "true";
  },
  created() {
    eventBus.$on('toggleInfoPanel', () => {
      this.toggleInfoPanel();
    })
  },
  components: {
    MintModal,
  },
  props: ['patch', 'loading', 'isCollapsed'],
  computed: {
    hasOwner: function() {
      return this.patch.land.owner.address;
    },
    ownerName() {
      if ((this.$store.state.auth.status.loggedIn) && (this.$store.state.auth.user.wallet.address == this.patch.land.owner.address)) {
        return "You";
      } else {
        return this.patch.land.owner.name || truncate_address(this.patch.land.owner.address);
      }
    },
    userLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data: function() {
    return {
      infoPanelVisible : false,
    };
  },
  methods: {
    toggleInfoPanel() {
      this.infoPanelVisible = !this.infoPanelVisible;
      localStorage.infoPanelVisible = this.infoPanelVisible;
    },
  },
}
</script>

<style scoped>
	#patch-info-panel {
		position: fixed;
		bottom: 0;
		width: 100%;
		margin-bottom: 0;
		z-index: 999;
	}

  #collapse-info {
		overflow-y: scroll;
    max-height: 40vh;
  }

  #nft-badge {
    padding-left: 0px;
    padding-right: 10px;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .card-header {
    background-color: rgba(255, 255, 255, 0);
  }
</style>
