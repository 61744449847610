<template>
  <div id="main">
    <Navigation/>
    <router-view></router-view>
  </div>
</template>

<script>
import Navigation from './Navigation.vue'

export default {
  name: 'Main',
  components: {
    Navigation,
  },
  props: {
  },
  title: 'Mukabe',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #main {
    width: 100%;
    height: 100%;
  }
</style>
