import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronUp,
  faChevronDown,
  faHome,
  faShareAlt,
  faTh,
  faInfo,
  faEdit,
  faImage,
  faImages,
  faLink,
  faUser,
  faFlag,
  faShoppingCart,
  faEnvelope,
  faCertificate,
  faMap,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faTelegramPlane,
  faRedditAlien,
  faWhatsapp,
  faDiscord,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import VueSocialSharing from 'vue-social-sharing';

import { Chrome } from 'vue-color'

import titleMixin from './mixins/title';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin

Vue.use(IconsPlugin);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('color-picker', Chrome);

library.add(faChevronUp);
library.add(faChevronDown);
library.add(faHome);
library.add(faShareAlt);
library.add(faTh);
library.add(faInfo);
library.add(faEdit);
library.add(faImage);
library.add(faImages);
library.add(faLink);
library.add(faUser);
library.add(faFlag);
library.add(faShoppingCart);
library.add(faEnvelope);
library.add(faCertificate);
library.add(faMap);
library.add(faFacebook);
library.add(faTwitter);
library.add(faTelegramPlane);
library.add(faRedditAlien);
library.add(faWhatsapp);
library.add(faDiscord);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueSocialSharing);

Vue.mixin(titleMixin);

import { truncate_address } from './utils';
Vue.filter('truncate_address', truncate_address);

Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
