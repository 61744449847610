import service from './index';
import authHeader from './auth_header';

export function getUser(address) {
  return service().get(`/users/${address}/`, { headers: authHeader() });
}

export function updateUser(address, data) {
  return service().patch(`/users/${address}/`, data, { headers: authHeader() });
}
