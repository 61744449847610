<template>
  <b-modal
    id="modal-edit-land-info"
    ref="modal"
    title="Edit land info"
    @show="resetModal"
    @ok="handleOk"
    >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        id="input-group-title"
        label="Name"
        label-for="input-title"
        description="The name of your land."
        >
        <b-form-input
          id="input-title"
          v-model="newLand.name"
          placeholder="Enter name"
          ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { updateLand } from "/src/services/lands.js"

export default {
  name: "EditLandModal",
  props: ["land"],
  data() {
    return {
      newLand: {
        name: "",
      },
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
    },
    resetModal() {
      this.newLand.name = this.land.name;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      updateLand(
        this.land.id,
        {
          "name": this.newLand.name,
        }
      )
        .then(response => {
          this.land.name = response.data.name;
        })
      // eslint-disable-next-line no-unused-vars
        .catch(_ => {
          alert("Sorry, there was an error!");
        })
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-edit-land-info')
      })
    }
  },
}
</script>
