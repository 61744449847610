<template>
  <b-modal
    id="modal-share"
    title="Share"
    ok-only
    >
    <div class="my-4 text-center">
      <ShareNetwork
        network="twitter"
        :url="share.link"
        :title="share.title"
        :description="share.description"
        :quote="share.quote"
        :hashtags="share.hashtags"
        :twitterUser="share.twitterUser"
        class="separated"
        >
        <b-button variant="primary" class="mb-2 separated">
          <font-awesome-icon :icon="['fab', 'twitter']" /> Twitter
        </b-button>
      </ShareNetwork>
      <ShareNetwork
        network="facebook"
        :url="share.link"
        :title="share.title"
        :description="share.description"
        :quote="share.quote"
        :hashtags="share.hashtags"
        class="separated"
        >
        <b-button variant="primary" class="mb-2 separated">
          <font-awesome-icon :icon="['fab', 'facebook']" /> Facebook
        </b-button>
      </ShareNetwork>
      <ShareNetwork
        network="telegram"
        :url="share.link"
        :title="share.title"
        :description="share.description"
        class="separated"
        >
        <b-button variant="primary" class="mb-2 separated">
          <font-awesome-icon :icon="['fab', 'telegram-plane']" /> Telegram
        </b-button>
      </ShareNetwork>
      <ShareNetwork
        network="whatsapp"
        :url="share.link"
        :title="share.title"
        :description="share.description"
        class="separated"
        >
        <b-button variant="primary" class="mb-2 separated">
          <font-awesome-icon :icon="['fab', 'whatsapp']" /> WhatsApp
        </b-button>
      </ShareNetwork>
      <ShareNetwork
        network="reddit"
        :url="share.link"
        :title="share.title"
        class="separated"
        >
        <b-button variant="primary" class="mb-2 separated">
          <font-awesome-icon :icon="['fab', 'reddit-alien']" /> Reddit
        </b-button>
      </ShareNetwork>
    </div>
    <b-input-group class="mt-3">
      <b-form-input readonly ref="shareURL" :value="share.link"/>
      <b-input-group-append>
        <b-button
          v-on:click="copyToClipboard"
          >Copy to clipboard</b-button>
      </b-input-group-append>
    </b-input-group>
  </b-modal>
</template>

<script>
  export default {
    name: "ShareLink",
    components: {
    },
    props: ["share"],
    data() {
      return {
      }
    },
    methods: {
      copyToClipboard() {
        this.$refs.shareURL.select();
        document.execCommand("copy");
      }
    }
  }
</script>

<style scoped>
  .separated {
    padding-left: 5px;
    padding-right: 5px;
  }
</style>
