import Vue from "vue";
import VueRouter from 'vue-router'

import Main from "./components/Main.vue";
import Map from "./components/Map.vue";
import Land from "./components/Land.vue";
import User from "./components/User.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "/",
        name: "map",
        component: Map,
      },
      {
        path: "/:position",
        name: "patch",
        component: Map,
      },
      {
        path: "/lands/:id",
        name: "land",
        component: Land,
      },
      {
        path: "/users/:address",
        name: "user",
        component: User,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
