<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
  // Import custom SASS variable overrides, or alternatively
  // define your variable overrides here instead
  @import './custom.scss';

  // General style overrides and custom classes
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
  }

  html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    padding-bottom: 26px;

    font-size: 14px;
  }
</style>
