import service from './index';
import authHeader from './auth_header';

export function getLand(id) {
  return service().get(`/lands/${id}/`, { headers: authHeader() });
}

export function getLandPatches(id, page) {
  return service().get(`/patches/?land=${id}&page=${page}`, { headers: authHeader() });
}

export function getUserLands(address, page) {
  return service().get(`/lands/?owner=${address}&page=${page}`, { headers: authHeader() });
}

export function updateLand(id, data) {
  return service().patch(`/lands/${id}/`, data, { headers: authHeader() });
}
