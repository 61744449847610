import service from './index';
import authHeader from './auth_header';

export function getConnectedUserNFTs() {
  return service().get(`/me/nfts/`, { headers: authHeader() });
}

export function attachNFTToPatch(position, nft) {
  return service().post(
    `/patches/${position}/nft/`,
    {
      blockchain: nft.blockchain,
      contract_address: nft.contract_address,
      token_id: nft.token_id,
    },
    { headers: authHeader() },
  );
}
