<template>
  <b-modal
    id="modal-image"
    ref="modal"
    title="Upload image"
    @show="resetModal"
    ok-only
    ok-title="Back"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-form-checkbox
        id="check-ratio"
        v-model="image_ratio"
        name="check-ratio"
        value="1:1"
        unchecked-value="free"
        @input="imageRatio"
        >
        Force square (1:1)
      </b-form-checkbox>

      <slim-cropper :options="slimOptions">
        <input type="file" name="slim"/>
      </slim-cropper>
    </b-overlay>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="outline-danger"
          size="gm"
          class="float-left"
          @click="closeModal"
          :disabled="loading"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          size="gm"
          class="float-right"
          @click="upload"
          :disabled="loading"
        >
          Upload
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { createUploadImageSignedURL, uploadImage } from "/src/services/patches.js"
import Slim from './slim/slim.vue'

export default {
  name: "ImageModal",
  components: {
    'slim-cropper': Slim
  },
  props: ["patch"],
  data: (instance) => {
    return {
      loading: false,
      image_ratio: '1:1',
      slimOptions: {
        //ratio: 'free',
        ratio: '1:1',
        // initialImage: require('../assets/test.jpg'),
        'force-type': 'png',
        'upload-method': 'put',
        // eslint-disable-next-line no-unused-vars
        didInit: function slimInit (data, slim) { // called when slim has initialized
          //// slim instance reference
          //console.log(slim);

          //// current slim data object and slim reference
          //console.log(data);

          instance.cropper = slim;
        },
        'serviceFormat': 'file',
        // eslint-disable-next-line no-unused-vars
        service: function (images, progress, success, failure, slim) {
          //// slim instance reference
          //console.log(slim)

          //// form data to post to server
          //// set serviceFormat to "file" to receive an array of files
          //console.log(images)

          //// call these methods to handle upload state
          //console.log(progress, success, failure)

          instance.loading = true;
          createUploadImageSignedURL(instance.patch.position)
            .then(response => {
              //console.log(response.data);
              //console.log(response.data.filename);
              //console.log(response.data.url);

              uploadImage(images[0], response.data.filename, response.data.url)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                  instance.loading = false;
                  instance.closeModal();
                  instance.$bvToast.toast("The image was uploaded. The map will be updated shortly, please refresh the page in a few minutes.", {
                    title: 'Uploaded!',
                    toaster: "b-toaster-bottom-right",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: false,
                    solid: true,
                  });
                })
                .catch(error => {
                  instance.loading = false;
                  alert("Sorry, there was an error! Please, try again later.");
                  console.error(error)
                });
            })
            .catch(error => {
              instance.loading = false;
              alert("Sorry, there was an error! Please, try again later.");
              console.error(error)
            });
        },
        // push: false,
      }
    }
  },
  methods: {
    upload() {
      console.log("Uploading...");
      console.log(this.cropper);

      // eslint-disable-next-line no-unused-vars
      this.cropper.upload(function(error, data, response){
        // Done uploading!
      });
    },
    resetModal() {
    },
    closeModal() {
      this.$bvModal.hide('modal-image');
    },
    imageRatio() {
      // eslint-disable-next-line no-unused-vars
      this.cropper.setRatio(this.image_ratio, function(data) {
        // done!
      })
    },
  },
}
</script>
